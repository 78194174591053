export default {
  saveAudience: {
    url: '/audiences/save-audience',
    method: 'post',
  },
  getAudience: {
    url: '/audiences/get-audiences',
    method: 'post',
  },
  getStakeholders: {
    url: '/audiences/get-stakeholders',
    method: 'post',
  },
  getGroupLevels: {
    url: '/audiences/get-group-levels',
    method: 'get',
  },
  getLocationLevels: {
    url: '/audiences/get-location-levels',
    method: 'post',
  },
  getLocationAndLevels: {
    url: '/audiences/get-locations-and-levels',
    method: 'post',
  },
  getGroupsByLevels: {
    url: '/audiences/get-groups-by-group-level',
    method: 'post',
  },
  getGroupsLocationsLevels: {
    url: '/audiences/get-locations-groups-levels',
    method: 'get',
  },
  getActiveProject: {
    url: '/project/active-project',
    method: 'get',
  },
  saveTargetAudiencesColumns: {
    url: '/project/target-audience-columns',
    method: 'post',
  },
  getJobRole: {
    url: 'list/get-job-roles',
    method: 'get',
  },
  saveAudienceStakeholders: {
    url: '/audiences/save-audience-stakeholder',
    method: 'post',
  },
  getTargetAudienceStats: {
    url: '/audiences/get-target-audience-statistics',
    method: 'get',
  },
  updateGroupsLocationsJobs: {
    url: '/audiences/update-groups-locations-jobs',
    method: 'post',
  },
};
