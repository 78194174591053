import { memo, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import ReportTableData from './ReportTableData/ReportTableData';
import useDownloadPDF from '../../surveys/hooks/useDownloadPDF';
import ReportModel from '../../ui-elements/ReportModel';
import AddDescriptionButton from './AddDscriptionButton';
import { Button, Tooltip } from 'antd';
import { useReportsState } from '../context/reports';

const ChartBox = memo(({ id, title, children, height = '80%', chartBoxStyle }) => {
  return (
    <div id={id}>
      {title && <Title className="chart-title">{title}</Title>}
      <div style={{ minHeight: '21rem', height: height, overflow: 'hidden', width: '100%', ...chartBoxStyle }}>{children}</div>
    </div>
  );
});

const ReportChart = ({
  id,
  children,
  title,
  width,
  height = '28rem',
  chartBoxStyle,
  data,
  tableColumns,
  description,
  defaultDescription,
  reportSection,
  reportInfoId,
  showDescription = true,
  getListData,
  exportListData,
  background = 'white',
  modelTitle,
  hideDetailedPopup = false
}) => {
  console.log({
    hideDetailedPopup,
    title
  })
  const [open, setOpen] = useState(false);
  const [listData, setListData] = useState([]);
  const [listColumns, setListColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const { reportsPDFExport, } = useReportsState();

  const pdfTargetRef = useRef();

  const { downloadingSurvey: isDownloadingPDF, downloadPDF } = useDownloadPDF({
    orientation: 'l',
    templateRef: pdfTargetRef,
    fileName: `${title}.pdf`,
    title,
  });
  const handleDownloadPDF = () => {
    downloadPDF(id);
  };

  const getColumns = (data, cols = []) => {
    if ((data?.length === 0 || data === undefined) && !cols.length) {
      return [];
    } else if (cols.length) {
      return cols.map((key) => ({
        title: key,
        dataIndex: key,
        key: key,
      }))
    } else {
      const columns = Object.keys(data[0]).map((key) => ({
        title: key,
        dataIndex: key,
        key: key,
      }));
      return columns;
    }
  };

  function transformData(inputArray) {
    // Step 1: Find all unique keys in the array
    let allKeys = new Set();
    inputArray.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        allKeys.add(key);
      });
    });

    // Step 2: Transform each object in the array
    const transformedArray = inputArray.map((obj) => {
      // Step 3: Add missing keys with empty string as the value
      allKeys.forEach((key) => {
        if (!(key in obj)) {
          obj[key] = '';
        }
      });

      return obj;
    });

    return transformedArray;
  }

  const getData = (data) => {
    if (data?.length === 0 || data === undefined) {
      return [];
    } else {
      let maxKeysIndex = 0;
      let maxKeysCount = 0;
      data.forEach((item, index) => {
        const currentKeyCount = Object.keys(item).length;
        if (currentKeyCount > maxKeysCount) {
          maxKeysCount = currentKeyCount;
          maxKeysIndex = index;
        }
      });
      if (maxKeysIndex > 0) {
        const temp = data[maxKeysIndex];
        data[maxKeysIndex] = data[0];
        data[0] = temp;
      }
      return transformData(data);
    }
  };

  //

  useEffect(() => {
    if (data && !getListData) {
      const columns = getColumns(data, tableColumns);
      const tabledata = getData(data);
      setListData(tabledata);
      setListColumns(columns);
    }
  }, [data]);

  useEffect(() => {
    if (getListData && open) {
      loadMoreData(page);
    }
  }, [page, open]);

  const loadMoreData = async (page) => {
    if (loading || !hasMore) return;
    setLoading(true);

    // Simulate an API call to fetch data
    if (getListData && open) {
      const { tableData: newData } = await getListData(page, false);
      const columns = getColumns(newData);

      setListData((prevData) => [...prevData, ...newData]);

      if (listColumns.length === 0) {
        setListColumns(columns);
      }

      if (newData.length === 0) {
        setHasMore(false);
      }
    }
    setLoading(false);
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 500 && hasMore && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <Container maxWidth={width}>
      <ReportWrapper>
        <Warpper height={height} background={background}  id={id}>
          <ChartBox chartBoxStyle={chartBoxStyle} title={title}> {children} </ChartBox>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            {description && (
              <p
                style={{
                  width: '80%',
                  margin: '0px 20px',
                  padding: '4px 10px',
                  borderRadius: '5px',
                  background: '#FAFAFA',
                  color: '#7F7F7F',
                  marginRight: 'auto',
                }}
              >
                {description}
              </p>
            )}
            {!reportsPDFExport  && <div style={{ height: '40px', marginLeft: 'auto' }} className={'hideforPDF'}>
              {!hideDetailedPopup && <FontAwesomeIcon
                className="forSvgRemove"
                onClick={() => setOpen(true)}
                style={{
                  cursor: 'pointer',
                  '--fa-primary-color': '#A6A6A6',
                  '--fa-secondary-color': '#A6A6A6',
                  width: '32px',
                  height: 'auto',
                }}
                icon={duotone('magnifying-glass-plus')}
              />}
            </div>}
          </div>
        </Warpper>
        {showDescription && (
          <AddDescriptionButton
            description={description}
            defaultDescription={defaultDescription}
            reportKey={id}
            reportSection={reportSection}
            reportInfoId={reportInfoId}
          />
        )}
      </ReportWrapper>

      {/* <Popup showIcon title={title} footer={null} open={open} close={() => setOpen(false)} width="80%"> */}
      <ReportModel title={modelTitle || title} open={open} close={() => setOpen(false)} footer={null} width="80%">
        <div style={{ width: '100%' }}>
          <div style={{ textAlign: 'right', width: '100%', marginBottom: '30px' }}>
            <Tooltip title="Export to PDF">
              <FontAwesomeIcon
                onClick={() => handleDownloadPDF()}
                style={{
                  cursor: 'pointer',
                  '--fa-primary-color': '#BFBFBF',
                  '--fa-secondary-color': '#BFBFBF',
                  width: '32px',
                  height: 'auto',
                }}
                className={isDownloadingPDF ? 'fa-spin' : ''}
                icon={isDownloadingPDF ? duotone('spinner-third') : duotone('file-pdf')}
              />
            </Tooltip>
          </div>
          <ChartBoxContainer  ref={pdfTargetRef} >
            <ChartBox id={id} height="100%"> {children} </ChartBox>
          </ChartBoxContainer>
          {listData?.length > 0 && (
            <ReportTableData
              columns={listColumns}
              data={listData}
              handleScroll={handleScroll}
              exportListData={exportListData}
              title={title}
            />
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0px 0px' }}>
          <Button
            style={{
              backgroundColor: '#0066B2',
              color: '#FFFFFF',
              borderRadius: '16px',
              width: '100px',
              fontSize: '16',
              fontWeight: 500,
              lineHeight: '16px',
            }}
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </div>
      </ReportModel>
      {/* </Popup> */}
    </Container>
  );
};

const ReportWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  width: 100%;

  *{
    box-sizing: border-box;
  }

  @media screen and (min-width: 1024px) {
    width: ${({ maxWidth }) => maxWidth};
  }
`;

const Warpper = styled.div`
  height: ${({ height }) => height || '28rem'};
  width: 100%;
  padding: 17px 17px 10px 17px;
  border-radius: 7px;
  border: ${({ background }) => background && '0px 4px 4px 0px #00000026'} ;
  background-color: ${({ background }) => background};
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: ${({ background }) => background && '0px 4px 4px 0px #00000026'};
  margin-bottom: 10px;
`;

const Title = styled.p`
  font-size: 1rem;
  font-weight: 500;
`;

const ChartBoxContainer = styled.div`
  width: 100%;
  min-height: 20rem;
  height: 100%;
  #Stats{
    justify-content: center;
    >div{
      width: 70% !important;
      margin: 15px !important;
    }
  };
`;

export default ReportChart;
