import { useEffect, useState } from 'react';
import { DataService } from '../../config/dataService/dataService';
import SurveyTemplateForm from './SurveyTemplateForm';
import { useParams, useSearchParams } from 'react-router-dom';
import { Alert, Spin } from 'antd';
import NoSurvey from './components/Preview/NoSurvey';
import { decode } from './utils/utils';

export default function PublicSurveyPage() {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const isPrint = searchParams.get('print');
  const [surveyDataLoading, setSurveyDataLoading] = useState(true);
  const [surveyData, setSurveyData] = useState();
  const [expirationMessage, setExpirationMessage] = useState()
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    getSurvey();
  }, []);

  useEffect(() => {
    if (isPrint && surveyData) {
      window.addEventListener('afterprint', () => {
        window.close();
      });
      window.print();
    }
  }, [isPrint, surveyData]);
  
  const getSurvey = async () => {
    try {
      const response = await DataService.get(`/surveys/get-public-survey/${decode(params.surveyId)}`);
      if (response.status === 200) {
        setSurveyData(response.data);
      }
      if(response.status === 400) {
        setExpirationMessage(response.data.message)
      }
    } catch (err) {
      console.info(err);
    } finally {
      setSurveyDataLoading(false);
    }
  };

  return (
    <div style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', padding: '20px 0' }}>
      {surveyDataLoading ? (
        <LoadingState />
      ) : (
        <>
          {!surveyData && <NoSurvey expirationMessage={expirationMessage} />}
          {!formSubmitted && surveyData && (
            <SurveyTemplateForm templateData={surveyData?.survey_template} onSubmit={() => setFormSubmitted(true)} />
          )}
        </>
      )}

      {formSubmitted && (
        <div>
          <Alert message={surveyData.thankYouMessage} type="success" />
        </div>
      )}
    </div>
  );
}

const LoadingState = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Spin />
    </div>
  );
};
