import axios from 'axios';
import { setupCache } from 'axios-cache-adapter-fixed';
import { getItem } from '../../utility/localStorageControl';
import Cookies from 'js-cookie';

const API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/`;

const authHeader = () => ({
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
});

const authResetHeader = () => ({
  Authorization: `Bearer ${getItem('reset_password_token')}`,
});

// Set up cache with axios-cache-adapter
const cache = setupCache({
  maxAge: 45 * 60 * 1000, // Cache duration: 45 minutes
  exclude: { query: false }, // Cache responses with query parameters
});

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    'Content-Type': 'application/json',
  },
  adapter: cache.adapter, // Use the cache adapter
});

class DataService {
  static get(path = '') {
    return client({
      method: 'GET',
      url: path,
      headers: { ...authHeader() },
    }).then((response) => {
      return response;
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  static post(path = '', data = {}, optionalHeader = {}) {
    return client({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    }).then(async (response) => {
      if (clearCacheOnPost[path] !== undefined)
      {
        if (typeof clearCacheOnPost[path] === "string")
        {
          if (clearCacheOnPost[path] === "all")
          {
            for (const [key, value] of Object.entries(cache.store.store)) 
            {
              await cache.store.removeItem(key);
            }
          }
          else
          {
            for (const [key, value] of Object.entries(cache.store.store)) 
            {
              if (key.indexOf(clearCacheOnPost[path]) !== -1)
              {
                await cache.store.removeItem(key);
              }
            }
          }
        }
        else if (typeof clearCacheOnPost[path] === "object")
        {
          for(var ind in clearCacheOnPost[path])
          {
            for (const [key, value] of Object.entries(cache.store.store)) 
            {
              if (key.indexOf(clearCacheOnPost[path][ind]) !== -1)
              {
                await cache.store.removeItem(key);
              }
            }
          }
        }
      }
      return response;
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  static postResetPassword(path = '', data = {}, optionalHeader = {}) {
    return client({
      method: 'POST',
      url: path,
      data,
      headers: { ...authResetHeader(), ...optionalHeader },
    });
  }

  static patch(path = '', data = {}) {
    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = '', data = {}) {
    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static delete(path = '') {
    return client({
      method: 'DELETE',
      url: path,
      headers: { ...authHeader() },
    });
  }

  static getBlob(path = '') {
    return client({
      url: path,
      method: 'GET',
      responseType: 'blob',
      headers: { ...authHeader() },
    }).then(response =>
      window.URL.createObjectURL(
        new Blob([response.data])
      )
    )
  }

  static postBlob(path = '', data) {
    return client({
      url: path,
      method: 'POST',
      data,
      responseType: 'blob',
      headers: { ...authHeader() },
    }).then(response =>
      window.URL.createObjectURL(
        new Blob([response.data])
      )
    )
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = { ...headers, Authorization: `Bearer ${localStorage.getItem('access_token')}` };

  return requestConfig;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    error.config.status = response.status;
    error.config.data = response.data;
    const originalRequest = error.config;
    if (response) {
      if (response.status === 500) {
        // do something here
        return error;
      }
      else if (response.status === 400 && response.data.code === 403) {
        // do something here
        //Remove ALL Cookies
        localStorage.clear();
        Cookies.remove('logedIn');
        window.location.href = '/';
        //navigate(`/`)
      }
      else if (response.status === 403) {
        // do something here
        //Remove ALL Cookies
        localStorage.clear();
        Cookies.remove('logedIn');
        window.location.href = '/';
        //navigate(`/`)
      }
      return originalRequest;
    }
    return Promise.reject(error);
  },
);
export { DataService };

const clearCacheOnPost = {
  '/users/save-visits' : '/users/get-visits',
  '/users/update-account-information' : ['/users/get-account-information','/tools/get-owners'],
  '/users/update-account-image' : '/users/get-account-information',
  
  '/users/create-account-user' : ['/users/get-account-all-user','/tools/get-owners'],
  '/users/delete-account-user' : ['/users/get-account-all-user','/tools/get-owners'],
  '/users/update-user-information' : ['/users/get-user-information','/tools/get-owners'],
  '/users/update-user-image' : ['/users/get-user-information'],
  
  '/auth/add-customer-card/users' : '/defaultData/get-customer-payment-methods',
  '/auth/delete-card-from-customer/users' : '/defaultData/get-customer-payment-methods',
  '/auth/set-customer-card-as-primary/users' : '/defaultData/get-customer-payment-methods',
  
  '/auth/add-customer-card/users' : '/defaultData/get-payment-methods',
  '/auth/delete-card-from-customer/users' : '/defaultData/get-payment-methods',
  '/auth/set-customer-card-as-primary/users' : '/defaultData/get-payment-methods',
  
  '/auth/cancel-deletion-customer-account/users' : '/defaultData/get-subscription-info',
  '/auth/restart-customer-subscription/users' : '/defaultData/get-subscription-info',
  '/auth/stop-subscription-cancellation/users' : '/defaultData/get-subscription-info',
  '/auth/pause-subscription/users' : '/defaultData/get-subscription-info',
  '/auth/resume-subscription/users' : '/defaultData/get-subscription-info',
  '/auth/delete-account-subscription/users' : '/defaultData/get-subscription-info',
  '/auth/update-customer-subscription/users' : '/defaultData/get-subscription-info',
  
  '/auth/logout/users' : 'all',
  '/project/save-project-assessment' : '/project/get-project-assessment',
  'auth/update-customer-to-paid/users' : ['/auth/veify/users', '/tools/ping-tools', '/defaultData/get-subscription-info', '/defaultData/get-payment-methods', '/defaultData/get-customer-payment-methods'],
  '/auth/update-customer-subscription/users' : ['/auth/veify/users', '/tools/ping-tools', '/defaultData/get-subscription-info', '/defaultData/get-payment-methods', '/defaultData/get-customer-payment-methods'],
  '/auth/cancel-subscription/users' : ['/auth/veify/users', '/tools/ping-tools', '/defaultData/get-subscription-info', '/defaultData/get-payment-methods', '/defaultData/get-customer-payment-methods'],
  '/auth/pause-subscription/users' : ['/auth/veify/users', '/tools/ping-tools', '/defaultData/get-subscription-info', '/defaultData/get-payment-methods', '/defaultData/get-customer-payment-methods'],
  '/auth/resume-subscription/users' : ['/auth/veify/users', '/tools/ping-tools', '/defaultData/get-subscription-info', '/defaultData/get-payment-methods', '/defaultData/get-customer-payment-methods'],
  '/auth/delete-account-subscription/users' : ['/auth/veify/users', '/tools/ping-tools', '/defaultData/get-subscription-info', '/defaultData/get-payment-methods', '/defaultData/get-customer-payment-methods'],
  '/auth/cancel-deletion-customer-account/users' : ['/auth/veify/users', '/tools/ping-tools', '/defaultData/get-subscription-info', '/defaultData/get-payment-methods', '/defaultData/get-customer-payment-methods'],
  '/auth/restart-customer-subscription/users' : ['/auth/veify/users', '/tools/ping-tools', '/defaultData/get-subscription-info', '/defaultData/get-payment-methods', '/defaultData/get-customer-payment-methods'],
  '/auth/stop-subscription-cancellation/users' : ['/auth/veify/users', '/tools/ping-tools', '/defaultData/get-subscription-info', '/defaultData/get-payment-methods', '/defaultData/get-customer-payment-methods'],
  '/auth/convert-to-trial/users' : ['/auth/veify/users', '/tools/ping-tools', '/defaultData/get-subscription-info', '/defaultData/get-payment-methods', '/defaultData/get-customer-payment-methods'],
  '/project/set-close-project' : ['/project/get-user-projects', '/project/get-all-project'],
  '/project/set-reopen-project' : ['/project/get-user-projects', '/project/get-all-project'],
  '/project/create-project' : ['/project/get-user-projects', '/project/get-all-project'],
  '/project/update-project-name' : ['/project/get-user-projects', '/project/get-all-project'],

  '/audiences/save-audience' : ['/audiences/get-target-audience-statistics', '/audiences/get-locations-groups-levels', '/audiences/get-location-levels', '/list/get-job-roles','/list/get-locations', '/list/get-stakeholders', '/list/get-group-levels'],
  '/audiences/delete-individual' : ['/audiences/get-target-audience-statistics', '/audiences/get-locations-groups-levels', '/audiences/get-location-levels', '/list/get-job-roles','/list/get-locations', '/list/get-stakeholders', '/list/get-group-levels'],
  '/audiences/save-audience-stakeholder' : ['/audiences/get-target-audience-statistics', '/audiences/get-locations-groups-levels', '/audiences/get-location-levels', '/list/get-job-roles','/list/get-locations', '/list/get-stakeholders', '/list/get-group-levels'],
  '/audiences/delete-all-audiences' : ['/audiences/get-target-audience-statistics', '/audiences/get-locations-groups-levels', '/audiences/get-location-levels', '/list/get-job-roles','/list/get-locations', '/list/get-stakeholders', '/list/get-group-levels'],
  '/audiences/delete-group-level-data' : ['/audiences/get-target-audience-statistics', '/audiences/get-locations-groups-levels', '/audiences/get-location-levels', '/list/get-job-roles','/list/get-locations', '/list/get-stakeholders', '/list/get-group-levels'],
  '/audiences/add-group' : ['/audiences/get-target-audience-statistics', '/audiences/get-locations-groups-levels', '/audiences/get-location-levels', '/list/get-job-roles','/list/get-locations', '/list/get-stakeholders', '/list/get-group-levels'],
  '/audiences/delete-individual' : ['/audiences/get-target-audience-statistics', '/audiences/get-locations-groups-levels', '/audiences/get-location-levels', '/list/get-job-roles','/list/get-locations', '/list/get-stakeholders', '/list/get-group-levels'],
  '/audiences/update-groups-locations-jobs' : ['/audiences/get-target-audience-statistics', '/audiences/get-locations-groups-levels', '/audiences/get-location-levels', '/list/get-job-roles','/list/get-locations', '/list/get-stakeholders', '/list/get-group-levels'],
  '/audiences/update-group-name' : ['/audiences/get-locations-groups-levels', '/list/get-group-levels'],
  '/audiences/save-change-types' : ['/audiences/get-change-types'],

  '/project-info/save-amazon-project' : ['/project-info/get-amazon-project-member','/project-info/get-amazon-project-details', '/project-info/get-amazon-action-review'],
  '/project-info/save-amazon-import' : ['/project-info/get-amazon-project-member','/project-info/get-amazon-project-details', '/project-info/get-amazon-action-review'],
  '/project-info/delete-amazon-project-member' : ['/project-info/get-amazon-project-member','/project-info/get-amazon-project-details', '/project-info/get-amazon-action-review'],

  '/project-info/save-amazon-action-review' : ['/project-info/get-amazon-project-member','/project-info/get-amazon-project-details', '/project-info/get-amazon-action-review', '/project-info/get-amazon-action-review-key-value'],
  '/project-info/delete-amazon-action-review-key-value' : ['/project-info/get-amazon-project-member','/project-info/get-amazon-project-details', '/project-info/get-amazon-action-review', '/project-info/get-amazon-action-review-key-value'],

  '/tools/save-cadence' : '/tools/get-cadence',
  '/tools/save-communication-channels' : '/free-tools/get-communication-channels',

  '/tools/change-save-answer' : ['/tools/change-get-questions'],

  '/tools/change-get-question-answer' : ['/tools/change-get-questions'],
  '/free-tools/change-assign-default-question' : ['/tools/change-get-questions'],
  '/tools/save-amazon-post-implementation' : ['/tools/get-cadence', '/tools/get-project-implementation','/tools/get-data-for-audience'],
  '/tools/import-post-implementation' : ['/tools/get-project-implementation','/tools/get-data-for-audience'],

  '/tools/save-communication-objectives' : ['/free-tools/get-communication-objectives', '/free-tools/get-communication-types','/free-tools/get-communication-success_metric'],
  '/tools/save-communication-types' : ['/free-tools/get-communication-objectives', '/free-tools/get-communication-types','/free-tools/get-communication-success_metric'],
  '/tools/save-communication-success_metric' : ['/free-tools/get-communication-objectives', '/free-tools/get-communication-types','/free-tools/get-communication-success_metric'],
  '/tools/communication-save-score' : ['/free-tools/get-communication-objectives', '/free-tools/get-communication-types','/free-tools/get-communication-success_metric'],
  '/tools/save-communication-status' : ['/free-tools/get-communication-objectives', '/free-tools/get-communication-types','/free-tools/get-communication-success_metric'],
  '/tools/delete-communication' : ['/free-tools/get-communication-objectives', '/free-tools/get-communication-types','/free-tools/get-communication-success_metric'],
  '/tools/delete-communication-type' : ['/free-tools/get-communication-objectives', '/free-tools/get-communication-types','/free-tools/get-communication-success_metric'],
  '/tools/import-communication' : ['/free-tools/get-communication-objectives', '/free-tools/get-communication-types','/free-tools/get-communication-success_metric'],

  '/tools/checklist-complete-update' : ['/free-tools/get-checklist','/free-tools/get-checklist-count', '/free-tools/get-phases'],
  '/tools/checklist-update' : ['/free-tools/get-checklist','/free-tools/get-checklist-count', '/free-tools/get-phases'],
  '/tools/add-task' : ['/free-tools/get-checklist','/free-tools/get-checklist-count', '/free-tools/get-phases'],

  '/tools/save-answer' : ['/free-tools/get-question-answer','/free-tools/get-questions'],
  '/tools/save-question' : ['/free-tools/get-question-answer','/free-tools/get-questions'],

  '/tools/save-resistance-key-reasons' : ['/free-tools/get-resistance-key-reasons'],

  '/tools/save-training' : ['/tools/get-all-training','/tools/get-training-passed-failed','/tools/get-level-of-training-needed', '/tools/get-training-list'],
  '/tools/get-single-training' : ['/tools/get-all-training','/tools/get-training-passed-failed','/tools/get-level-of-training-needed', '/tools/get-training-list'],
  '/tools/save-training-score' : ['/tools/get-all-training','/tools/get-training-passed-failed','/tools/get-level-of-training-needed', '/tools/get-training-list'],
  '/tools/get-single-training-main' : ['/tools/get-all-training','/tools/get-training-passed-failed','/tools/get-level-of-training-needed', '/tools/get-training-list'],
  '/tools/add-training-course-audeince' : ['/tools/get-all-training','/tools/get-training-passed-failed','/tools/get-level-of-training-needed', '/tools/get-training-list'],
  '/tools/delete-training-course-audeince' : ['/tools/get-all-training','/tools/get-training-passed-failed','/tools/get-level-of-training-needed', '/tools/get-training-list'],
  '/tools/get-training-list' : ['/tools/get-all-training','/tools/get-training-passed-failed','/tools/get-level-of-training-needed', '/tools/get-training-list'],
  '/tools/get-rating' : ['/tools/get-all-training','/tools/get-training-passed-failed','/tools/get-level-of-training-needed', '/tools/get-training-list'],
  '/tools/get-trainees' : ['/tools/get-all-training','/tools/get-training-passed-failed','/tools/get-level-of-training-needed', '/tools/get-training-list'],
  '/tools/check-training' : ['/tools/get-all-training','/tools/get-training-passed-failed','/tools/get-level-of-training-needed', '/tools/get-training-list'],
  '/tools/check-training' : ['/tools/get-all-training','/tools/get-training-passed-failed','/tools/get-level-of-training-needed', '/tools/get-training-list'],
  '/tools/copy-training' : ['/tools/get-all-training','/tools/get-training-passed-failed','/tools/get-level-of-training-needed', '/tools/get-training-list'],
  '/tools/delete-training' : ['/tools/get-all-training','/tools/get-training-passed-failed','/tools/get-level-of-training-needed', '/tools/get-training-list'],
  '/tools/get-single-training-course' : ['/tools/get-all-training','/tools/get-training-passed-failed','/tools/get-level-of-training-needed', '/tools/get-training-list'],
  '/tools/import-training' : ['/tools/get-all-training','/tools/get-training-passed-failed','/tools/get-level-of-training-needed', '/tools/get-training-list'],
}