import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import  * as ProIcons from '@fortawesome/pro-duotone-svg-icons'

import { Menu } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import propTypes from 'prop-types';

function MenuItems({ userTheme, sidebarData, isFreeAccount, organizationalSlug, openKeys, openSubKeys, setOpenKeys, toggleCollapsed }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  sidebarData.sidebar.forEach(sidebar => {
    let icon = sidebar.faicon;
    library.add(ProIcons[icon]);
  });

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const path = '/' + organizationalSlug + '/main-dashboard';

  const pathName = window.location.pathname;
  const pathArray = pathName ? pathName.split('/' + organizationalSlug) : [];
  const mainPath = pathArray.length > 1 ? pathArray[1] : '';
  const mainPathSplit = mainPath.split('/');
  // const [openKeys, setOpenKeys] = React.useState(
  //   !topMenu ? [`${mainPathSplit.length == 3 ? mainPathSplit[mainPathSplit.length-2] + `-` + mainPathSplit[mainPathSplit.length-1] : mainPathSplit.length == 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  // );
  // const [openSubKeys, setOpenSubKeys] = useState([]);
  const onOpenChange = (keys) => {
    //setOpenSubKeys(keys);
    const [firstChildrenExecute, firstChildrenRoutes] = setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
    navigate(`/${organizationalSlug}/${firstChildrenRoutes}`);
  };

  const findComponent = (iconName, iconType, routes, hasLink, onClickCallback) => {
    //style={{"--fa-primary-color": `${userTheme.sidebarIconPrimaryColor}`, "--fa-secondary-color": `${userTheme.sidebarIconSecondaryColor}`}}
    //iconName
    return (
      hasLink ? (
        <NavLink className="" to={`${routes}`} onClick={onClickCallback}>
          <FontAwesomeIcon icon={`fa-${iconType} fa-${iconName}`} size="2xl" style={{"--fa-primary-color": `${userTheme.sidebarIconPrimaryColor}`, "--fa-secondary-color": `${userTheme.sidebarIconSecondaryColor}`}} />
        </NavLink>
      ) : (
        <FontAwesomeIcon icon={`fa-${iconType} fa-${iconName}`} size="2xl" style={{"--fa-primary-color": `${userTheme.sidebarIconPrimaryColor}`, "--fa-secondary-color": `${userTheme.sidebarIconSecondaryColor}`}} />
      )
    );
  };

  const onClick = (item) => {
    if (item.key === openKeys[openKeys.length - 1])
    {
      navigate(0);
    }
    setOpenKeys([item.key]);
    /*let itemKey = item.key;
    sidebarData.sidebar.forEach(sidebar => {
      if (sidebar.childrens.length == 0 && sidebar.key === item.key)
      {
        return ;
      }
      else
      {
        sidebar.childrens.forEach(children => {
          if (item.key == children.key)
          {
            itemKey = sidebar.key;
          }
          return ;
        });
      }
    });
    setOpenSubKeys([itemKey]);*/
  };
  const is_marked_as_cancel = localStorage.getItem('is_marked_as_cancel') === 'true';
  const is_cancelled = localStorage.getItem('is_cancelled') === 'true';
  const is_paused = localStorage.getItem('is_paused') === 'true';
  const marked_for_delete = localStorage.getItem('marked_for_delete') === 'true';

  const is_paid = localStorage.getItem('is_paid') === 'true';
  let is_amazon = localStorage.getItem('is_amazon') === 'true';
  let is_offline = localStorage.getItem('offline_account') === 'true';
  let MenuItemsData = [];
  sidebarData.sidebar.forEach(sidebar => {
      if (sidebar.routes === "subscription" && is_offline)
      {
        //Don't do anything
      }
      else
      {
        let routes = '/' + organizationalSlug + '/' + sidebar.routes;
        let key = sidebar.key;
        let cnt = 0;
        sidebar.childrens.forEach(children => {
          if (children.only_free === undefined || (children.only_free !== undefined && isFreeAccount))
          {
            cnt++;
          }
        });
        if (cnt == 0)
        {
          if (sidebar.params !== "")
          {
            routes = '/' + organizationalSlug + '/' + sidebar.routes + '/' + sidebar.params;
          }
          let menuitems;
          if (sidebar.params === "target-audience")
          {
            menuitems = getItem(
              <NavLink className="" to={'#'} onClick={() => {
                if (mainPathSplit[mainPathSplit.length-1] !== "target-audience")
                {
                  window.location.href=`${routes}`;
                }
              }}>
                {t(key)}
              </NavLink>,
              key,
              !topMenu && findComponent(sidebar.icon, sidebar.icon_type, '#', true, () => {
                if (mainPathSplit[mainPathSplit.length-1] !== "target-audience")
                {
                  window.location.href=`${routes}`;
                }
              })
            );
          }
          else
          {
            menuitems = getItem(
              <NavLink className="" to={`${routes}`}>
                {t(key)}
              </NavLink>,
              key,
              !topMenu && findComponent(sidebar.icon, sidebar.icon_type, routes, true, () => {})
            );
          }
          MenuItemsData.push(menuitems);
        }
        else
        {
          let childs = [];
          sidebar.childrens.forEach(children => {
            let show = false;
            
            if (children.paid === undefined)
            {
              show = true;
            }
            else if (children.paid !== undefined && children.paid == false)
            {
              show = true;
            }
            else
            {
              if (children.paid && is_paid)
              {
                if (children.show_default === undefined)
                {
                  show = true;
                }
                else
                {
                  if (children.show_default)
                  {
                    if (children.show_in_paused === false && children.show_in_cancel_request === false && children.show_in_cancelled === false)
                    {
                      if (is_paused)
                      {
                        show = false;
                      }
                      else if (is_cancelled)
                      {
                        show = false;
                      }
                      else if (is_marked_as_cancel)
                      {
                        show = false;
                      }
                      else
                      {
                        show = true;
                      }
                    }
                    else if (children.show_in_paused === true && children.show_in_cancel_request === false && children.show_in_cancelled === false)
                    {
                      if (is_paused)
                      {
                        show = true;
                        if (is_marked_as_cancel)
                        {
                          show = false;
                        }
                        if (is_cancelled)
                        {
                          show = false;
                        }
                      }
                      else if (is_cancelled)
                      {
                        show = false;
                      }
                      else if (is_marked_as_cancel)
                      {
                        show = false;
                      }
                      else
                      {
                        show = true;
                      }
                    }
                    else
                    {
                      show = true;
                    }
                  }
                  else
                  {
                    if (children.show_in_paused)
                    {
                      if (is_paused)
                      {
                        show = true;
                      }
                      if (is_marked_as_cancel)
                      {
                        show = false;
                      }
                      if (is_cancelled)
                      {
                        show = false;
                      }
                    }
                    if (children.show_in_cancel_request)
                    {
                      if (is_marked_as_cancel)
                      {
                        show = true;
                      }
                      else
                      {
                        show = false;
                      }
                    }
                    if (children.show_in_cancelled)
                    {
                      if (is_cancelled)
                      {
                        show = true;
                      }
                      else
                      {
                        show = false;
                      }
                    }
                  }
                }
              }
            }
            if (children.all === undefined)
            {
              show = true;
            }
            else
            {
              if (children.all)
              {
                show = true;
              }
            }
            if (marked_for_delete)
            {
              if (is_paid)
              {
                if (['transaction-history', 'payment-method', 'delete-account'].includes(children.routes))
                {
                  show = true;
                }
                else
                {
                  show = false;
                }
              }
              else
              {
                if (['delete-account'].includes(children.routes))
                {
                  show = true;
                }
                else
                {
                  show = false;
                }
              }
            }
            if (is_paid)
            {
              if (['subscribe-now'].includes(children.routes) && show)
              {
                show = false;
              }
            }
            if (show)
            {
              if (children.only_free === undefined || (children.only_free !== undefined && isFreeAccount))
              {
                if (children.params !== "")
                {
                  routes = '/' + organizationalSlug + '/' + sidebar.routes + '/' + children.params;
                }
                let key_to_show = children.key;
                if (is_amazon && key_to_show === "tools-dashboard-ocm-tools")
                {
                    key_to_show = "tools-dashboard-cmi-tools";
                }
                let child = getItem(
                  <NavLink className="" to={`${routes}`}>
                    {t(key_to_show)}
                  </NavLink>,
                  children.key,
                  null //No Need for the icon
                );
                childs.push(child);
              }
            }
          });
          let menuitems = getItem(
            t(key),
            key,
            !topMenu && findComponent(sidebar.icon, sidebar.icon_type, routes, false),
            childs
          );
          if (is_amazon)
          {
            if (menuitems.key !== 'subscription')
            {
              MenuItemsData.push(menuitems);
            }
          }
          else
          {
            MenuItemsData.push(menuitems);
          }
        }
      }
  });
  const items = MenuItemsData;

  return (
    <>
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'inline'}
      // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      openKeys={openSubKeys}
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length == 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      //overflowedIndicator={<UilEllipsisV />}
      selectedKeys={openKeys}
      items={items}
    />
    </>
  );
}

export default MenuItems;

