import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from 'antd';
import { Link } from 'react-router-dom';
import styled from "styled-components";

const { Search } = Input;

export const MainPublicContentWrapper = styled.section`
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    min-height: 272px;
    padding: 10px 20px;
    background-color: #CBD1E9;
    border: 1px solid #CCC;
`;

export const LibraryContainer = styled.section`
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    min-height: 272px;
`;

export const LibraryImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 74px;
    min-height: 272px;
    font-size: 36px;
    color: #fff;
    font-weight: 700;
    z-index: 1000;
`;

export const BackgroundImage = styled.img`
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
`;

export const Overlay = styled.div`
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
`;

export const LibraryTitle = styled.h1`
    position: relative;
    font-family: Inter, sans-serif;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0;
    margin-top: 5px;
    width: 100%;
    padding: 98px 60px 97px;
    text-align: center;
    @media (max-width: 991px) {
        max-width: 100%;
        padding: 0 20px;
    }
`;

export const ContentAreaWrapper = styled.div`
    background-color: #fff;
    min-height: 641px;
    margin-top: 35px;
    /* padding: 0px 0px 20px 30px; */
    width: 100%;
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

export const LicenseContainer = styled.div`
    display: flex;
    padding: 0px 100px;
    height: 200px;
    align-items: start;
    justify-content: center;
    @media (max-width: 991px) {
        flex-direction: column;
        align-items: stretch;
        gap: 0;
        padding: 0px;
    }
`;

export const InfoColumn = styled.section`
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    height: 100%;
    margin-left: 0;
    padding: 2% 5%;
    @media (max-width: 991px) {
        width: 100%;
        padding: 0 40px 30px;
    }
`;

export const InfoContent = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
    gap: 20px;
    font-size: 18px;
    color: var(--Text-Color, #404040);
    font-weight: 400;
    line-height: 35px;
    padding: 0 20px;
    @media (max-width: 991px) {
        margin-top: 40px;
        flex-wrap: wrap;
    }
`;

export const InfoIcon = styled.div`
    font-size: 50px;
    color: #FFD05B;
    --fa-primary-color: #FFD05B;
    --fa-secondary-color: #FFD05B;
`;

export const InfoText = styled.p`
    font-family: Inter, sans-serif;
    flex-grow: 1;
    flex-basis: auto;
    line-height: 35px;
    font-size: 18px;
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

export const SubscriptionLink = styled.a`
    text-decoration: underline;
`;

export const Divider = styled.div`
    background-color: #bfbfbf;
    width: 1px;
    height: 164px;
    border: 1px solid rgba(191, 191, 191, 1);
`;

/*
 *  Category
 */
export const MainContentWrapper = styled.section`
    display: flex;
    flex-direction: row;
    line-height: normal;
    border-width: 5px 5px 5px 5px;
    border-style: solid;
    border-color: rgba(122, 198, 255, 0.25);
    min-height: 715px;
    height: 100%;
    margin-bottom: 40px;
    align-items: start;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    border-radius: 10px 0px 0px 0px;
    @media (max-width: 991px) {
        width: 100%;
    }
`;

export const CategoryContainer = styled.main`
    display: flex;
    width: 25%;
    flex-direction: column;
    font-size: 18px;
    color: var(--Text-Color, #404040);
    font-weight: 400;
    /* margin: 0 auto; */
    height: 100%;
    border-right: 5px solid rgba(122, 198, 255, 0.25);
`;

export const CMSContentWrapper = styled.main`
    display: flex;
    width: 75%;
    flex-direction: column;
    font-size: 18px;
    color: var(--Text-Color, #404040);
    font-weight: 400;
    height: 100%;
`;

export const CategoryListWrapper = styled.section`
    display: flex;
    /* margin-top: 13px; */
    width: 100%;
    flex-direction: column;
    .ant-list-items {
        cursor: pointer;
        padding-top: 0;
        li.ant-list-item {
            padding: 10px 16px;
            margin-top: 0;
            &.active {
                padding: 0;
                button {
                    background-color: rgba(122, 198, 255, 0.25);
                    display: flex;
                    width: 100%;
                    padding: 20px 16px 20px 16px;
                    cursor: pointer;
                    span.activeTitle {
                        font-family: Inter, sans-serif;
                        flex-grow: 1;
                        flex-basis: auto;
                        margin: auto 0;
                        font-weight: 500;
                    }
                }
            }
            &:first-child {
                margin-top: 23px;
                border-block-start: 1px solid rgba(5, 5, 5, 0.06);
            }
            &:hover {
               background-color: rgba(191, 191, 191, 0.25);
            }
        }
    }
`;

export const StyledSearch = styled(Search)`
    .ant-input-wrapper {
        border-radius: 5px;
        border: 1px solid rgba(217, 217, 217, 0.85);
    }

    .ant-input {
        font-family: Inter, sans-serif;
        font-size: 14px;
        color: #878788;
    }

    .ant-input-search-button {
        background-color: #fff;
        border-left: 1px solid rgba(217, 217, 217, 0.85);
    }

    @media (max-width: 991px) {
        white-space: initial;
    }
`;

export const StyledIcon = styled(FontAwesomeIcon)`
    width: 34px;
    height: 34px;
    color: var(--Icon-Color---Inactive, #bfbfbf);
    margin: auto 0;
    cursor: pointer;
`;

export const CMSItemWrapper = styled.article`
    display: flex;
    align-items: center;
    gap: 17px;
    @media (max-width: 991px) {
        flex-wrap: wrap;
    }
`;

export const CMSImage = styled.img`
    /* aspect-ratio: 1.35; */
    object-fit: auto;
    object-position: center;
    width: 100px;
    align-self: stretch;
    max-width: 100%;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.05);
    object-fit: cover;
    object-position: center;
`;

export const Separator = styled.div`
    aspect-ratio: 0.04;
    object-fit: auto;
    object-position: center;
    width: 1px;
    align-self: stretch;
    margin: auto 0;
    background-color: #d9d9d9;
`;

export const CMSTitle = styled.h3`
    font-family: Inter, sans-serif;
    text-decoration-line: underline;
    align-self: stretch;
    flex-grow: 1;
    flex-basis: auto;
    margin: auto 0;
    font-size: 20px;
    color: var(--Text-Color, #404040);
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

export const CMSListWrapper = styled.section`
    display: flex;
    margin-top: 35px;
    width: 100%;
    flex-direction: column;
    font-size: 20px;
    color: var(--Text-Color, #404040);
    padding: 0 36px;
    @media (max-width: 991px) {
        max-width: 100%;
        padding: 0 20px;
    }
`;

export const CMSListTitle = styled.h2`
    font: 700 24px Inter, sans-serif;
    margin-bottom: 0px;
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

export const CMSDivider = styled.hr`
    background-color: #d9d9d9;
    margin: 24px 0;
    height: 1px;
    border: none;
    border-top: 1px dashed rgba(217, 217, 217, 1);
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

export const Container = styled.main`
    display: flex;
    max-width: 100%;
    flex-direction: column;
    font-weight: 400;
    padding: 23px 0 21px;
`;

export const TopSection = styled.section`
    align-self: flex-end;
    display: flex;
    margin-right: 25px;
    width: 95%;
    max-width: 100%;
    gap: 20px;
    font-size: 14px;
    color: #878788;
    white-space: nowrap;
    justify-content: space-between;
    @media (max-width: 991px) {
        margin-right: 10px;
        flex-wrap: wrap;
        white-space: initial;
    }
`;

export const SearchWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    @media (max-width: 991px) {
        white-space: initial;
    }
`;

export const EmptyMessage = styled.p`
  font-family: Inter, sans-serif;
  font-size: 16px;
  color: #878788;
  text-align: center;
  margin-top: 20px;
`;

export const ScrollableListContainer = styled.div`
    height: 900px;
    overflow-y: auto;
    border-radius: 4px;
    margin-top: 20px;

    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background-color: #FFFFFF;
        border: 1px solid #E0F2FF;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #E0F2FF;
        border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: #7AC6FF;
    }
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;
    display: contents;
`;

export const CMSPageContentWrapper = styled.main`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    min-height: 650px;
    padding: 20px;
`;

export const CMSPublicPageContentWrapper = styled.main`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    min-height: 650px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
    margin-bottom: 40px;
    margin-top: 20px;
`;

export const HeaderWrapper = styled.section`
    background-color: #fff;
    width: 100%;
    @media (max-width: 991px) {
        padding: 20px;
    }
`;

export const PageContentWrapper = styled.section`
    position: relative;
    background-color: #fff;
    width: 100%;
    padding-top: 20px;
    line-height: 1.6 !important;
    hr {
        margin-bottom: 12px;
        display: block !important;
        border-width: 1px 0px 0px;
        border-style: solid none none;
        border-color: rgb(204, 204, 204);
        border-image: none;
        &:first-child {
            border-width: 0px 0px 0px;
            border-style: none none none;
        }
    }
    .post-content {
        font-size: 1.3rem;
        margin-bottom: 10px;
        line-height: 1.6;
    }
    p {
        width: 100%;
        font-weight: 400;
        color: #444;
        font-size: 19px;
        margin-bottom: 30px;
        display: block;
        line-height: 2;
        font-family: "georgia, palatino, serif";
    }
    table {
        p {
            margin-bottom: 0 !important;
        }
    }
    figure{
        display: table;
        margin: 1rem auto;
    }
    img {
        max-width: 100%;
        height: auto;
        vertical-align: middle;
    }
    .aligncenter {
        clear: both;
        display: block;
        margin: 0 auto 30px;
    }
    ul, ol {
        width: 100%;
        padding-top: 6px;
        list-style: none;
        position: relative; 
        padding-left: 25px; 
        margin: 0px 0px 30px 30px;
        li {
            line-height: 1.6;
            color: #444;
            padding-top: 6px;
            padding-bottom: 6px;
            font-size: 18px;
        }
        &:last-child {
            /* padding-bottom: 40px; */
            margin: 0;
        }
    }
    blockquote {
        padding: 60px 30px 10px 30px;
        font-size: 1.375rem;
        position: relative;
        background: #ccfbf2;
        border-radius: 6px;
        border: 1px solid #ccfbf2;
        box-shadow: 10px 10px 16px rgba(184, 185, 190, 0.3), -10px -10px 16px rgba(255, 255, 255, 0.5);
        margin-bottom: 30px;
        margin-top: 25px;
        &::before {
            content: "\f10e"; /* Verify this Unicode in Font Awesome 6 Pro */
            font-family: "Font Awesome 6 Pro";
            font-size: 1.875rem;
            position: absolute;
            top: 15px;
            left: 30px;
            line-height: 41px;
            z-index: 0;
            color: #1ab394;
            /* font-weight: 900; */
        }
        p { 
            margin-bottom: 15px;
        }
    }
    h1,
    h2,
    h3,
    h4, 
    h5, 
    h6 {
        line-height: 1.6; 
        color: #444; 
        margin-bottom: 20px;
    }
    h1 {
        font-size:2rem;
    }
    h2 {       
        font-size: 1.5rem;
    }
    a {
        color: #0a77cc;
        text-decoration: underline;
        span {
            color: #0a77cc;
            text-decoration: underline;
        }
    }
    table {
        margin: 0 0 1.5em;
        thead {
            th {
                background: #2f4050;
                border: 1px solid #2f4050;
                padding: 10px 15px;
                color: #fff;
                font-weight: 500;
            }
            td {
                background: #2f4050;
                border: 1px solid #2f4050;
                padding: 10px 15px;
                color: #fff;
                font-weight: 500;
            }
        }
        tbody {
            padding: 10px 15px;
            border-color: inherit;
            border-width: 1px;
            border-style: solid;
        }
        tr {
            padding: 10px 15px;
            border-color: inherit;
            border-width: 1px;
            border-style: solid;
            td {
                padding: 10px 15px;
                border-color: inherit;
                border-width: 1px;
                border-style: solid;
            }
        }
    }
    ul {
        width: 100%;
        margin: 0px 0px 30px 30px;
        li {
            &::before {
                content: "\f0a9"; /* Verify this Unicode in Font Awesome 6 Pro */
                font-family: 'Font Awesome 6 Pro';
                color: #1ab394;
                margin-right: 10px;
                /* font-weight: 900; */
                font-size: 16px;
            }
            list-style: none;
            position: relative;
            padding-left: 25px;
            font-size: 18px;
            line-height: 1.6;
            color: #444;
            padding-top: 6px;
            padding-bottom: 6px;
        }
        &:last-child {
            margin: 0;
        }
    }
    @media (max-width: 991px) {
        padding: 20px;
    }
`;

export const HeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100%;
    font-size: 24px;
    border-bottom: 1px solid #ccc;
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

export const PageTitle = styled.h1`
    color: #000;
    font-weight: 400;
    align-self: flex-start;
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

export const BackToListWrapper = styled.button`
    position: fixed;
    right: 48px;
    top: 80%;
    transform: translateY(-50%);
    z-index: 99999999;
    transform: translateY(-50%);
    border: none;
    border-radius: 5px 0 0 5px;
    background-color: #ffd05b;
    /* max-width: 119px; */
    max-width: 139px;
    color: #404040;
    /* font: 400 14px Inter, sans-serif; */
    font: 400 18px Inter, sans-serif;
    cursor: pointer;
    padding: 8px 10px;
    /* padding: 6px 8px; */
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #ffc82e;
    }
    &:focus {
        outline: 2px solid #404040;
        outline-offset: 2px;
    }

    @media (max-width: 991px) {
        left: 0px;
    }
    @media (max-width: 1200px){
        left: 80px;
    }
`;

export const BackToLisPublictWrapper = styled.button`
    position: fixed;
    right: 65px;
    top: 80%;
    transform: translateY(-50%);
    z-index: 99999999;
    transform: translateY(-50%);
    border: none;
    border-radius: 5px 0 0 5px;
    background-color: #ffd05b;
    /* max-width: 119px; */
    max-width: 139px;
    color: #404040;
    /* font: 400 14px Inter, sans-serif; */
    font: 400 18px Inter, sans-serif;
    cursor: pointer;
    padding: 8px 10px;
    /* padding: 6px 8px; */
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #ffc82e;
    }
    &:focus {
        outline: 2px solid #404040;
        outline-offset: 2px;
    }

    @media (max-width: 991px) {
        left: 0px;
    }
    @media (max-width: 1200px){
        left: 80px;
    }
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;


export const BackIcon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

//Breadcrumb CSS
export const HeaderBreadcrumb = styled.section`
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 39px 80px 39px 40px;
    color: var(--Text-Color, #404040);
    font: 400 12px Inter, sans-serif;
    margin: 0 45px;
    @media (max-width: 991px) {
        padding: 20px;
    }
    @media (max-width: 1200px) {
        padding: 25px;
    }
`;

export const TitleBreadcrumbLink = styled(Link)`
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    color: inherit;
    margin-right: 5px;
    &:hover {
        color: inherit;
        text-decoration: underline;
    }
`;

export const TitleBreadcrumb = styled.p`
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    color: inherit;
`;

export const RightArrowIcon = styled(FontAwesomeIcon)`
    width: 24px;
    height: 24px;
    color: var(--Icon-Color---Inactive, #0066B2);
    margin: auto 0;
    cursor: pointer;
    margin-right: 5px;
`;