import { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Space, Input, Spin, Select } from 'antd';
import styled from 'styled-components';
import RadioGroup from './components/StarRadioGroup';
import CheckboxGroup from './components/checkbox-group';
import TextEditor from '../tools/panel/widgets/TextEditor';
import { useReactToPrint } from 'react-to-print';
import SurveyAction from './components/SurveyAction';
import {
  faArrowDownToBracket,
  faArrowLeft,
  faEnvelope,
  faEye,
  faGear,
  faPencil,
  faPrint,
  faFloppyDisk,
} from '@fortawesome/pro-duotone-svg-icons';
import { DataService } from '../../config/dataService/dataService';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../components/buttons/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UploadLogo from './components/UploadLogo';
import StarRadioGrop from './components/StarRadioGroup/StarRadioGroup';
import Popup from './components/Popup/Popup';
import SendSurvey from './components/Popup/SendSurvey';
import RadioButtonsGroup from './components/RadioButtonsGroup';
import CustomInputNumber from './components/InputNumber';
import CustomDatePicker from './components/DatePicker';
import SurveyFooter from './components/SurveyFooter';
import surveyConfig from './survey_config.json'
import DownloadSurveyPopup from './components/DownloadSurveyPopup';
import useDownloadPDF from './hooks/useDownloadPDF';
import SurveyTemplateForm from './SurveyTemplateForm';
import SettingsPopup from './components/Popup/SettingsPopup';
import useNotificationController from '../../utility/useNotificationController';

const StyledTemplate = styled.div`
  border: solid 1px;
`;

const TemplateHeader = styled.div`
  background: #5dd3ff;
  padding: 20px 0 20px 0;
`;

const CustomInput = styled.input`
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  background: transparent;
  width: 100%;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 150px;
  border: 2px solid #5dd3ff;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  resize: none;
`;

const Editor = ({ content, onChange, onBlur, onFocus, visible }) => {
  const [isEditorLoaded, setEditorLoaded] = useState(false);
  const [value, setValue] = useState();
  const editorRef = useRef();

  useEffect(() => {
    setValue(content);
  }, []);

  useEffect(() => {
    if (isEditorLoaded && visible) {
      editorRef.current.focus();
    }
  }, [visible, isEditorLoaded]);

  const handleBlur = () => {
    onChange(value);
    onBlur();
  };

  return (
    <TextEditor
      onInit={(evt, editor) => {
        editorRef.current = editor;
        setEditorLoaded(true);
      }}
      toolbar_location={'top'}
      toolbar={
        'bold italic underline strikethrough forecolor backcolor | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify'
      }
      statusbar={false}
      height={200}
      content={content}
      onBlur={handleBlur}
      onFocus={onFocus}
      onEditorChange={(newValue) => setValue(newValue)}
    />
  );
};

const QuestionEditInput = ({ value, visible, onBlur, onChange }) => {
  const ref = useRef();

  useEffect(() => {
    if (visible) {
      ref.current.focus();
    }
  }, [visible]);

  return <Input ref={ref} value={value} onBlur={onBlur} onChange={onChange} />;
};

export default function SurveyTemplateEditor() {
  const {
    contextHolder,
    openError,
    openSuccess,
  } = useNotificationController();
  
  const [form] = Form.useForm();
  const templateRef = useRef();
  const [templateData, setTemplateData] = useState();
  const [surveyName, setSurveyName] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isGlobalEditing, setGlobalEditing] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [isPreviewVisible, setShowPreview] = useState(false);
  const organizationalSlug = localStorage.getItem('organizationalSlug');
  const activeProjectName = localStorage.getItem('active_project_name')
  const [showSettingModal, setShowSettingModal] = useState(false);
  const [settingsData, setSettingsData] = useState();
  const [showSendEmailPopup, setShowSendEmailPopup] = useState(false);
  const [fieldInEditingMode, setFieldInEditingMode] = useState(null);
  const [trainings, setTraining] = useState([])
  const [selectedTraining, setSelectedTraining] = useState()
  const [isDownloadSurveyPopOpen, setToggleDownloadSurveyPopup] = useState(false)

  const fetchSurvey = async () => {
    const url = `surveys/get-survey/${params.id}`;

    try {
      const response = await DataService.get(url);
      if(response.status === 200) {
        setTemplateData(response.data.survey_template);
        setSurveyName(response.data.surveyName || response.data.survey_template.key.replace(/_/g, ' '));
        setSettingsData(response.data);
      } else {
        navigate(`/${organizationalSlug}/surveys`)
      }
    } catch (err) {
      console.error(err)
    }

  };

  const fetchSurveyTemplate = async () => {
    try {
      const url = `surveys/get-survey-template/${params.id}`;
      const response = await DataService.get(url);
      if (response.status === 200) {
        if (params.action === 'create') {
          setSurveyName(response.data.key.replace(/_/g, ' '));
        }
        setTemplateData(response.data);
      }

      if (response.status === 401) {
        navigate(`/${organizationalSlug}/surveys`);
      }

    } catch (err) {
      console.error(err)
    }

  };

  const searchParams = new URLSearchParams(location.search);
  const queryGlobalEdit = searchParams.get('global-edit');

  useEffect(() => {
    if (queryGlobalEdit) {
      const parsed = JSON.parse(queryGlobalEdit);
      setGlobalEditing(parsed);
    }
  }, [location]);

  useEffect(() => {
    if (params.action === 'edit') {
      fetchSurvey();
    }

    if (params.action === 'create') {
      fetchSurveyTemplate();
    }
  }, [params]);
  const hasTraining = surveyConfig[templateData?.key]?.fetchTrainings
  useEffect(() => {
    const fetchTrainings = async () => {
      const url = `tools/get-training-list`;
      const response = await DataService.get(url);
      setTraining(response.data.trainings);
      const training = response.data.trainings?.[0]
      setSelectedTraining(training ? training?.id : null)
    }

    if (templateData && !trainings.length && hasTraining) {
      if (hasTraining) {
        fetchTrainings()
      }
    }
  }, [templateData])

  const isAmazon = localStorage.getItem('is_amazon') === 'true'

  const updateTemplate = async (formData) => {
    setSaving(true);
    const updatedQuestions = Object.keys(formData)
      .filter((key) => key.includes('question'))
      .map((key) => {
        const value = formData[key];
        const index = Number(key.split('-')[1]);
        const question = templateData.questions[index];
        question.text = value;
        return question;
      });
    const data = {
      surveyName,
      surveyTemplate: {
        ...templateData,
        title: formData.title,
        projectName: formData.projectName,
        descriptionLine2: formData.descriptionLine2,
        descriptionLine3: formData.descriptionLine3,
        questions: updatedQuestions,
        training_id: selectedTraining,
        isAmazon
      },
    };

    let url;
    let method;

    if (params.action === 'create') {
      url = 'surveys/create-survey';
      method = 'post';
    }

    if (params.action === 'edit') {
      url = `surveys/update-survey/${params.id}`;
      method = 'put';
    }

    try {
      const response = await DataService[method](url, data);
      setGlobalEditing(false);
      setFieldInEditingMode(null);
      setSurveyName(data.surveyName);

      setSaving(false);
      openSuccess('bottomRight', "Survey",  `Survey saved`);

      if (params.action === 'create') {
        navigate(`/${organizationalSlug}/surveys/edit/${response.data.id}`);
      }
    } catch (error) {
      console.error(error)
      openError('topLeft', 'Survey Error', "Something went wrong");
    }
  };

  const handleLogoChange = async (url) => {
    if (params.action !== 'edit') {
      return;
    }

    setSaving(true);

    const data = {
      surveyTemplate: {
        ...templateData,
        logo: url,
      },
    };
    try {
      await DataService.put(`surveys/update-survey/${params.id}`, data);
      await fetchSurvey();
      setGlobalEditing(false);
      setFieldInEditingMode(null);
      setSaving(false);
      openSuccess('bottomRight', "Survey Template",  `Template is saved`);
      setTemplateData({ ...templateData, logo: url });
    } catch (error) {
      openError('topLeft', 'Survey Error', "Something went wrong");
      console.error(error);
    }
  };


  const [isPrinting, setIsPrinting] = useState(false);

  // We store the resolve Promise being used in `onBeforeGetContent` here
  const promiseResolveRef = useRef(null);
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [isPrinting])

  const { downloadPDF, downloadingSurvey } = useDownloadPDF({
    templateRef,
    beforeDownload: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    afterDownload: () => {
      setIsPrinting(false)
      setToggleDownloadSurveyPopup(false)
    }
  })

  const printHandler = useReactToPrint({
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
      promiseResolveRef.current = null;
      setIsPrinting(false);
    },
    content: () => templateRef.current,
    documentTitle: () => `Survey`,
    copyStyles: true,
  });

  const handleOnBlur = () => {
    if (isGlobalEditing) {
      return;
    }
    setFieldInEditingMode(null);
    if (params.action === 'edit') {
      form.submit();
    }
  };

  const handleValuesChange = (changedField) => {
    let changedFieldKey = Object.keys(changedField)[0];
    const updatedTemplate = JSON.parse(JSON.stringify(templateData));

    if (changedFieldKey.includes('question')) {
      const value = changedField[changedFieldKey];
      const index = Number(changedFieldKey.split('-')[1]);
      updatedTemplate.questions[index].text = value;
    }

    updatedTemplate[changedFieldKey] = changedField[changedFieldKey];

    setTemplateData(updatedTemplate);
  };

  const renderActionsButtons = () => {
    const buttons = [];

    const btnSave = (
      <SurveyAction title={'Save'} loading={isSaving} icon={faFloppyDisk} onClick={() => form.submit()} />
    );

    const btnCancelEditing = (
      <Button
        htmlType="button"
        onClick={() => {
          setGlobalEditing(false);
          setFieldInEditingMode(null);
        }}
      >
        Cancel
      </Button>
    );

    const btnEdit = (
      <SurveyAction
        title={'edit'}
        icon={faPencil}
        onClick={(e) => {
          setGlobalEditing(true);
        }}
      />
    );

    if (params.action === 'create') {
      buttons.push(btnSave);
      if (!isGlobalEditing && !fieldInEditingMode) {
        buttons.push(btnEdit);
      } else {
        buttons.push(btnCancelEditing);
      }
    } else if (params.action === 'edit') {
      if (isGlobalEditing) {
        buttons.push(btnSave);
        buttons.push(btnCancelEditing);
      } else {
        buttons.push(
          !fieldInEditingMode ? btnEdit : null,
          <SurveyAction title={'Print'} icon={faPrint} onClick={printHandler} />,
          <SurveyAction title={'Download'} icon={faArrowDownToBracket} onClick={() => setToggleDownloadSurveyPopup(true)} />,
          <SurveyAction title={'Preview'} icon={faEye} onClick={() => setShowPreview(true)} />,
          <SurveyAction
            title={'Survey Settings'}
            icon={faGear}
            onClick={() => setShowSettingModal(true)}
            color={'#7F7F7F'}
          />,
          <SurveyAction
            title={'Send Survey'}
            icon={faEnvelope}
            onClick={() => setShowSendEmailPopup(true)}
            color={'#FEC002'}
          />,
        );
      }
    }

    return buttons;
  };

  const isFieldInEditMode = (fieldName) => {
    return isGlobalEditing || fieldInEditingMode === fieldName;
  };

  const renderEditableField = (controlName, placeholderMsg) => {
    const content = templateData[controlName];
    const isContentEmpty = content == null || content === '';

    return (
      <>
        {contextHolder}
        <div style={isFieldInEditMode(controlName) ? null : { height: 0, overflow: 'hidden', width: 0 }}>
          <Form.Item name={controlName} initialValue={content} noStyle>
            <Editor
              content={content}
              onBlur={handleOnBlur}
              onFocus={() => setFieldInEditingMode(controlName)}
              visible={isFieldInEditMode(controlName)}
            />
          </Form.Item>
        </div>

        <div
          onClick={() => setFieldInEditingMode(controlName)}
          style={{ display: isFieldInEditMode(controlName) ? 'none' : 'flex', alignItems: 'center', height: '100%' }}
        >
          {isContentEmpty && !isPrinting ? (
            <div
              style={{
                width: '100%',
                height: '50px',
                padding: '10px',
                display: 'flex',
                alignItems: 'center',
                border: 'dotted 1px',
              }}
            >
              {placeholderMsg}
            </div>
          ) : (
            <span style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: content }} />
          )}
        </div>
      </>
    );
  };
  return (
    <>
      <Spin tip={'Saving...'} spinning={isSaving}>
        <Row style={{ marginLeft: 30 }}>
          <Col span={18}>
            {isPrinting ? (<SurveyTemplateForm templateData={templateData} isPreviewMode ref={templateRef} />) :
              (
                <>
                  <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px', paddingTop: '20px' }}>
                    <Input
                      style={{
                        width: '100%',
                        maxWidth: '300px',
                        border: '2px solid #A6A6A6',
                        height: '37px',
                        textAlign: 'center'
                      }}
                      value={surveyName}
                      onChange={(e) => setSurveyName(e.target.value)}
                      onBlur={handleOnBlur}
                    />

                    {hasTraining && (
                      <StyledSelect
                        value={selectedTraining}
                        onBlur={handleOnBlur}
                        onChange={(v) => setSelectedTraining(v)}
                        options={trainings.map(training => ({ label: training.courseName, value: training.id }))}
                      />
                    )}
                  </Col>

                  <Row className="tools" style={{ marginTop: '20px', marginBottom: '10px' }}>
                    <Col span={4}>
                      <Button
                        htmlType="button"
                        onClick={() => navigate(`/${organizationalSlug}/surveys`)}
                        type="info"
                        shape
                        icon={<FontAwesomeIcon icon={faArrowLeft} />}
                      >
                        Back to Surveys
                      </Button>
                    </Col>
                    <Col span={17} offset={3}>
                      <Row style={{ gap: '20px', justifyContent: 'end' }}>{renderActionsButtons()}</Row>
                    </Col>
                  </Row>

                  <Form form={form} onFinish={updateTemplate} onValuesChange={handleValuesChange}>
                    {templateData && (
                      <StyledTemplate id="template">
                        <TemplateHeader>
                          <Row>
                            <Col offset={3} span={3}>
                              <UploadLogo
                                url={templateData.logo}
                                onLogoChange={handleLogoChange}
                                isEditing={params.action === 'edit'}
                                isAmazon={isAmazon}
                              />
                            </Col>
                            <Col offset={2} span={16}>
                              <div style={{ paddingRight: 10 }}>{renderEditableField('title', 'Write Template Title')}</div>
                            </Col>
                          </Row>
                        </TemplateHeader>

                        <div className="body">
                          <Row>
                            <Col span={18} offset={3}>
                              <div style={{ marginTop: 15 }}>
                                <p className="survey-text" style={{ display: 'flex', gap: 10, whiteSpace: 'nowrap' }}>
                                  {templateData.descriptionLine1?.split('{{input}}').map((stringPart, index) => {
                                    if (index === 1) {
                                      return (
                                        <>
                                          <Form.Item name="projectName" initialValue={templateData.projectName ? templateData.projectName : activeProjectName}>
                                            <CustomInput style={{ textAlign: 'center' }} />
                                          </Form.Item>

                                          <span>{stringPart}</span>
                                        </>
                                      );
                                    }
                                    return <span>{stringPart}</span>;
                                  })}
                                </p>

                                {renderEditableField('descriptionLine2', 'Write description here')}

                                <div style={{ marginTop: 30, marginBottom: 30 }}>
                                  {selectedTraining && (
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                                      <label style={{ flex: '0 0 auto', marginRight: 10 }}>Training Course:</label>
                                      <label>{trainings.find(training => training.id === selectedTraining)?.courseName}</label>
                                    </div>
                                  )}
                                  <div style={{ marginBottom: 20, display: 'flex' }}>
                                    <label style={{ flex: '0 0 auto', marginRight: 10 }}>Email address:</label>
                                    <CustomInput name="emailAaddress" required={templateData.emailAddress.required} />
                                  </div>

                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ flex: '0 0 auto', marginRight: 10 }}>Your department/group:</label>
                                    <CustomInput
                                      name="departmentOrGroup"
                                      required={templateData.departmentOrGroup.required}
                                    />
                                  </div>
                                </div>

                                {renderEditableField('descriptionLine3', 'Write description here')}

                                {templateData.questions.map((question, index) => (
                                  <div>
                                    <Form.Item
                                      style={
                                        isFieldInEditMode(`question-${index}`) ? null : { height: 0, overflow: 'hidden' }
                                      }
                                      name={`question-${index}`}
                                      initialValue={question.text}
                                    >
                                      <QuestionEditInput
                                        visible={fieldInEditingMode === `question-${index}`}
                                        onBlur={handleOnBlur}
                                      />
                                    </Form.Item>

                                    {!isFieldInEditMode(`question-${index}`) && (
                                      <p
                                        onClick={() => setFieldInEditingMode(`question-${index}`)}
                                        style={{ fontWeight: 'bold' }}
                                      >
                                        {question.text}
                                      </p>
                                    )}

                                    <p style={{ display: 'block' }}>{question.description}</p>

                                    {question.answerType === 'checkbox' && (
                                      <div style={{ display: 'flex', gap: 15 }}>
                                        <Form.Item>
                                          <CheckboxGroup options={question.options} />
                                        </Form.Item>
                                      </div>
                                    )}

                                    {question.answerType === 'radio' && (
                                      <div style={{ display: 'flex', gap: 15 }}>
                                        <Form.Item>
                                          <RadioButtonsGroup options={question.options} />
                                        </Form.Item>
                                      </div>
                                    )}

                                    {question.answerType === 'date' && (
                                      <Space size={'large'}>
                                        {question.options.map((option) => (
                                          <Form.Item key={option.key}>
                                            <CustomDatePicker label={option.text} />
                                          </Form.Item>
                                        ))}
                                      </Space>
                                    )}

                                    {question.answerType === 'number' &&
                                      question.options.map((option) => (
                                        <Form.Item key={option.key}>
                                          <CustomInputNumber label={option.text} />
                                        </Form.Item>
                                      ))}

                                    {question.answerType === 'short_input' &&
                                      question.options.map((option) => (
                                        <Form.Item key={option.key}>
                                          <CustomInputNumber label={option.text} />
                                        </Form.Item>
                                      ))}

                                    <Form.Item>
                                      {question.answerType === 'number_rating' && (
                                        <RadioGroup
                                          options={question.options.map((option) => ({
                                            label: option.toString(),
                                            value: option.toString(),
                                          }))}
                                          optionType="button"
                                        />
                                      )}

                                      {question.answerType === 'star_rating' && (
                                        <StarRadioGrop
                                          numberOfStar={question.ratingProps.starCount}
                                          title={question.ratingProps.heading}
                                        />
                                      )}

                                      {question.answerType === 'textarea' && <StyledTextArea />}
                                    </Form.Item>
                                  </div>
                                ))}
                                <SurveyFooter />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </StyledTemplate>
                    )}
                  </Form>
                </>
              )}

          </Col>
        </Row>
      </Spin>

      {settingsData && showSettingModal && (
        <SettingsPopup
          open={showSettingModal}
          onClose={() => setShowSettingModal(false)}
          initial={settingsData}
          onSubmit={fetchSurvey}
        />
      )}

      <SendSurvey surveyData={settingsData} open={showSendEmailPopup} onClose={() => setShowSendEmailPopup(false)} />

      <Popup title={'Preview'} open={isPreviewVisible} close={() => setShowPreview(false)} footer={null}>
        <div style={{ marginTop: '20px' }}>
          <SurveyTemplateForm templateData={templateData} isPreviewMode />
        </div>
      </Popup>

      <DownloadSurveyPopup
        open={isDownloadSurveyPopOpen}
        isDownloadingSurvey={downloadingSurvey}
        onClose={() => setToggleDownloadSurveyPopup(false)}
        onDownload={(downloadType) => {
          if (downloadType === 'pdf') {
            downloadPDF()
          }
        }} />
    </>
  );
}


const StyledSelect = styled(Select)`
  width: 300px;
  .ant-select-selector {
    border: solid 2px #A6A6A6 !important;
  }
`;